import Vue from 'vue'
import axios from 'axios'
import {params} from '@/common/const'
//import { Toast } from 'vant';
//import toast from 'components/common/toast'
import router from '@/router'
export function request(config) {
    const vue = new Vue(); 
    const err = (error) => {
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 401:     
                    // 清除token
                    localStorage.removeItem('token');               
                    router.replace({                        
                        path: '/login',                        
                        query: { redirect: router.currentRoute.fullPath } 
                    });
                    break;
                default: 
                    vue.$toast.show(error.response.data.error); 
                    break;
            }
          //console.log(error.response.data.error)
          return Promise.reject(error)
        }else{
            vue.$toast.show('请检查您的网络'); 
        }
        return Promise.reject(error)
    }
    //let token = window.localStorage.getItem("accessToken")
    //baseURL: 'https://app.igolife.net/api/v1/',
    //1,创建axios实例
    const instance = axios.create({
        baseURL: params.debug?params.api_test_url:params.api_base_url,
        timeout: 5000,
        headers:{
            'Content-Type':'application/json',
            'Accept': 'application/json'
        }
    })
    //2，axios拦截器
    instance.interceptors.request.use(config => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况        
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token        
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码        
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。 
        const token = localStorage.getItem('token');        
        token && (config.headers.Authorization = token); 
        return config
    }, err => {

    })
    //2.1 响应拦截
    instance.interceptors.response.use(res => {
        return res.data
    }, err)
    //3,发送真正的网络请求
    return instance(config)
}

export function request4(config) {
    const vue = new Vue(); 
    const err = (error) => {
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 401:     
                    // 清除token
                    localStorage.removeItem('token');               
                    router.replace({                        
                        path: '/login',                        
                        query: { redirect: router.currentRoute.fullPath } 
                    });
                    break;
                default: 
                    vue.$toast.show(error.response.data.error); 
                    break;
            }
          //console.log(error.response.data.error)
          return Promise.reject(error)
        }else{
            vue.$toast.show('请检查您的网络'); 
        }
        return Promise.reject(error)
    }
    //let token = window.localStorage.getItem("accessToken")
    //baseURL: 'https://app.igolife.net/api/v1/',
    //1,创建axios实例
    const instance = axios.create({
        baseURL: params.debug?params.api_test_url:params.api_base_url,
        timeout: 5000,
        headers:{
            'Content-Type':'application/json',
            'Accept': 'application/json',
            'Authorization':'',
        }
    })
    //2，axios拦截器
    instance.interceptors.request.use(config => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况        
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token        
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码        
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。 
        const token = localStorage.getItem('token');        
        token && (config.headers.Authorization = token); 
        return config
    }, err => {

    })
    //2.1 响应拦截
    instance.interceptors.response.use(res => {
        return res.data;
    }, err)
    //3,发送真正的网络请求
    return instance(config)
}
export function request2(config) {
    const vue = new Vue(); 
    const err = (error) => {
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 401:     
                    // 清除token
                    localStorage.removeItem('token');  
                    if(config.url != '/cart_items'){
                        router.replace({                        
                            path: '/login',                        
                            query: { redirect: router.currentRoute.fullPath } 
                        }); 
                    }
                    
                    break;
                default: 
                    vue.$toast.show(error.response.data.error); 
                    break;
            }
          console.log(error.response.status)
          return Promise.reject(error)
        }else{
            vue.$toast.show('请检查您的网络'); 
        }
        return Promise.reject(error)
    }
    //let token = window.localStorage.getItem("accessToken")
    //baseURL: 'https://app.igolife.net/api/v1/',
    //1,创建axios实例
    const instance = axios.create({
        baseURL: params.debug?params.api_test_url_v2:params.api_base_url_v2,
        timeout: 5000,
        headers:{
            'Content-Type':'application/json',
            'Accept': 'application/json'
        }
    })
    //2，axios拦截器
    instance.interceptors.request.use(config => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况        
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token        
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码        
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。 
        const token = localStorage.getItem('token');   
        console.log('token:'+token);     
        token && (config.headers.Authorization = token); 
        return config
    }, err => {

    })
    //2.1 响应拦截
    instance.interceptors.response.use(res => {
        return res.data
    }, err)
    //3,发送真正的网络请求
    return instance(config)
}
export function request3(config) {
    const vue = new Vue(); 
    const err = (error) => {
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 401:
                    return Promise.reject(error)
                    break;
                default: 
                    vue.$toast.show(error.response.data.error); 
                    break;
            }
          console.log(error.response.status)
          return Promise.reject(error)
        }else{
            vue.$toast.show('请检查您的网络'); 
        }
        return Promise.reject(error)
    }
    //let token = window.localStorage.getItem("accessToken")
    //baseURL: 'https://app.igolife.net/api/v1/',
    //1,创建axios实例
    const instance = axios.create({
        baseURL: params.debug?params.api_test_url_v2:params.api_base_url_v2,
        timeout: 5000,
        headers:{
            'Content-Type':'application/json',
            'Accept': 'application/json'
        }
    })
    //2，axios拦截器
    instance.interceptors.request.use(config => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况        
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token        
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码        
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。 
        const token = localStorage.getItem('token');   
        console.log('token:'+token);     
        token && (config.headers.Authorization = token); 
        return config
    }, err => {

    })
    //2.1 响应拦截
    instance.interceptors.response.use(res => {
        return res.data
    }, err)
    //3,发送真正的网络请求
    return instance(config)
}
export function post(url,data = {},config={}){
    const vue = new Vue(); 
    return new Promise((resolve,reject) => {
        const token = localStorage.getItem('token');        
        token && (config.headers.Authorization = token); 
      axios.post('https://app.igolife.net/api/v1'+url,data,config)
           .then(response => {
             resolve(response.data);
           },err => {
            if (err.response && err.response.status) {
                if(err.response.status == '401'){
                    localStorage.removeItem('token');               
                    router.replace({                        
                        path: '/login',                        
                        query: { redirect: router.currentRoute.fullPath } 
                    });
                }else{
                    vue.$toast.show(err.response.data.error); 
                }
            }
             reject(err)
           })
    })
  }