const params = {
    debug: false,
    api_base_url: 'https://orange.igolife.net/api/v1/',
    api_test_url: 'https://orange-staging.igolife.net/api/v1/',
    api_base_url_v2: 'https://orange.igolife.net/api/v1/',
    api_test_url_v2: 'https://orange-staging.igolife.net/api/v1/',
    h5_pro:'https://orange-h5.igolife.net/',
    h5_test:'https://orange-h5-staging.igolife.net/',
}
export {params}
